import React, { useMemo, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FormWrapper, FormFooter } from '../style'
import { SliderInput } from '../FormComponents/shared/SliderInput'
import { BombTileGrid } from './BombTileGrid'
import { useBombsGameStore } from '@/store/useBombsGameStore'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import { GameButton } from '@/components/shared/Button/GameButton'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { FormLayout } from '@/components/Forms/FormComponents/shared/FormLayout'
import { SDoubleInputRow } from '../FormComponents/shared/FormLayout/styles'
import { getMaxCount } from '@/lib/crypto'
import { useBalances } from '@/hooks/useBalances'
import { usePathGameName } from '@/hooks/usePathGameName'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useBombsGameState } from '@/store/useGameStateStore'
import { AppGameName } from '@/chains/types'
import { useShallow } from 'zustand/react/shallow'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'
import { AmountInputField } from '../FormComponents/shared/AmountInputField'
import { NumberOfEntriesField } from '../FormComponents/shared/NumberOfEntriesField'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'

export interface BombsFormData {
  entryAmount: number
  bombCount: number
  numberOfEntries: number
}

export const BombsForm: React.FC = () => {
  const gameName = usePathGameName()
  const isMobileScreen = useIsBreakpoint('sm')
  const networkStyle = useNetworkStyle()
  const balances = useBalances()
  const balanceNumber = Number(balances.currency) || 0

  // click sfx
  const soundContext = useSound()

  const {
    bombCount,
    selectedTiles,
    entryAmount,
    selectTile,
    setBombCount,
    setEntryAmount,
    isReselectingTiles,
    toggleIsReselectingTiles,
    setNumberOfEntries,
  } = useBombsGameStore(
    useShallow(state => ({
      bombCount: state.bombCount,
      selectedTiles: state.selectedTiles,
      entryAmount: state.entryAmount,
      selectTile: state.selectTile,
      setBombCount: state.setBombCount,
      setEntryAmount: state.setEntryAmount,
      isReselectingTiles: state.isReselectingTiles,
      toggleIsReselectingTiles: state.toggleIsReselectingTiles,
      setNumberOfEntries: state.setNumberOfEntries,
    }))
  )
  const { type } = useBombsGameState(useShallow(state => ({ type: state.type })))
  const { isGameAnimating } = useIsGameAnimating()

  const { control, watch, setValue } = useForm<BombsFormData>({
    defaultValues: {
      entryAmount: 0,
      bombCount: bombCount,
      numberOfEntries: 1,
    },
  })
  const formData = watch()
  const { numberOfEntries } = formData

  useEffect(() => {
    setBombCount(formData.bombCount)
  }, [formData.bombCount, setBombCount])

  useEffect(() => {
    setEntryAmount(formData.entryAmount)
  }, [formData.entryAmount, setEntryAmount])

  useEffect(() => {
    setValue('bombCount', bombCount)
  }, [bombCount, setValue])

  useEffect(() => {
    setNumberOfEntries(formData.numberOfEntries)
  }, [formData.numberOfEntries, setNumberOfEntries])

  const handleTileClick = (index: number) => {
    if (!isGameAnimating) {
      selectTile(index)
      if (type === 'IDLE') {
        soundContext.playSound('TileSelect', 0.5, 1)
      }
    }
  }

  const calculateMaxBombCount = useCallback(() => {
    const selectedTileCount = selectedTiles.filter(Boolean).length
    return Math.min(24, 25 - selectedTileCount)
  }, [selectedTiles])

  const maxBombCount = useMemo(calculateMaxBombCount, [calculateMaxBombCount])

  const convertSelectedTilesToSelectedTileIndexes = useCallback(() => {
    return selectedTiles.reduce((indexesOfTrue: number[], currentValue: boolean, index: number) => {
      if (currentValue) {
        indexesOfTrue.push(index)
      }
      return indexesOfTrue
    }, [])
  }, [selectedTiles])

  const selectedTileIndexes = useMemo(convertSelectedTilesToSelectedTileIndexes, [
    convertSelectedTilesToSelectedTileIndexes,
  ])

  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone({
    bombCount: formData.bombCount,
    revealCount: selectedTileIndexes.length || 1,
  })

  useEffect(() => {
    if (bombCount > maxBombCount) {
      setBombCount(maxBombCount)
    }
  }, [maxBombCount, bombCount, setBombCount])

  const [maxCountForSide, setMaxCountForSide] = useState(DEFAULT_MAX_COUNT)

  useEffect(() => {
    if (gameName !== AppGameName.Bombs) return
    const maxCount = getMaxCount(gameName, {
      bombCount: formData.bombCount,
      revealCount: selectedTileIndexes.length || 1,
    } as any)
    // @NOTE: I could have done what I have done below with a single if but using if and else if makes it easier to understand
    if (formData.numberOfEntries > maxCount) {
      // @NOTE: If their current count is above the new max count, I am setting their count to be the new max count
      setValue('numberOfEntries', maxCount)
    } else if (maxCountForSide === formData.numberOfEntries && maxCount > maxCountForSide) {
      // @NOTE: If someone had fulled the slider to have max count, and if they move the slider for win chance that causes the max count to increase, I autyo increase their selected count to be new max
      setValue('numberOfEntries', maxCount)
    }
    setMaxCountForSide(maxCount)
  }, [
    formData.bombCount,
    selectedTileIndexes.length,
    formData.numberOfEntries,
    setValue,
    maxCountForSide,
    gameName,
  ])

  return (
    <FormWrapper>
      <FormLayout>
        <SliderInput
          label='Bomb Count'
          label2={`Max: ${maxBombCount}`}
          name='bombCount'
          control={control}
          min={1}
          max={maxBombCount}
          step={1}
          disabled={isGameAnimating}
        />
        <SDoubleInputRow className='mobile-form'>
          <AmountInputField
            control={control}
            maxNumber={1_000_000_000}
            entryAmount={entryAmount}
            balanceNumber={balanceNumber}
            balances={{ ...balances, currency: Number(balances.currency) }}
            bufferedMaxEntryAmount={bufferedMaxEntryAmount}
            isGameAnimating={isGameAnimating}
            stepZone={stepZone}
            networkStyle={networkStyle}
          />
          <NumberOfEntriesField
            isMobileScreen={isMobileScreen}
            control={control}
            maxCountForSide={maxCountForSide}
            numberOfEntries={numberOfEntries}
            entryAmount={entryAmount}
            isGameAnimating={isGameAnimating}
          />
        </SDoubleInputRow>
        {!isMobileScreen && (
          <BombTileGrid
            selectedTiles={selectedTiles}
            onTileClick={handleTileClick}
            maxSelections={25 - bombCount}
            isReselectingTiles={isReselectingTiles}
            toggleIsReselectingTiles={toggleIsReselectingTiles}
          />
        )}
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{
            ...formData,
            side: {
              bombCount,
              revealCount: selectedTileIndexes.length,
              revealIndexes: selectedTileIndexes,
            },
          }}
          entryAmountNum={entryAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}
