import { useForm } from 'react-hook-form'
import numeral from 'numeral'
import { FormFooter, FormTab, FormWrapper } from '../style'
import { ModeButton } from '@/components/shared/Button/style'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { CoinFlipSelection } from '@/lib/crypto/coinFlip'
import { clamp, ensureNumber } from '@/utils/math'
import { useCoinFlipGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'
import { GameButton } from '@/components/shared/Button/GameButton'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { FormLayout } from '../FormComponents/shared/FormLayout'
import { SDoubleInputRow } from '../FormComponents/shared/FormLayout/styles'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useBalances } from '@/hooks/useBalances'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'
import { AmountInputField } from '../FormComponents/shared/AmountInputField'
import { NumberOfEntriesField } from '../FormComponents/shared/NumberOfEntriesField'

export const CoinFlipForm = () => {
  const networkStyle = useNetworkStyle()
  const balances = useBalances()

  const balanceNumber = Number(balances.currency)

  const { entry, setEntry } = useCoinFlipGameState(
    useShallow(state => ({
      entry: state.entry,
      setEntry: state.setEntry,
    }))
  )
  const isMobileScreen = useIsBreakpoint('sm')
  const { isGameAnimating } = useIsGameAnimating()

  const { control, watch } = useForm<ISUContractForm>({
    defaultValues: {
      entryAmount: 0,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
    },
    mode: 'onChange',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  })
  const formData = watch()
  const { entryAmount, numberOfEntries } = formData

  const entryAmountNum = numeral(entryAmount).value() || 0

  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone(formData.side)

  // @NOTE: This is a workaround with react-hook-form so that numberOfEntries updates the global state
  // Whenever it is change. We need to move away from react-hook-form
  useEffect(() => {
    const value = clamp(ensureNumber(numberOfEntries), 1, 20) // Clamp so entryCount is between 1 and 20
    // setEntryCount(value)
    setEntry({ entryCount: value })
  }, [numberOfEntries, setEntry])

  return (
    <FormWrapper>
      <FormLayout>
        <FormTab $tabs={2} className='mobile-tabs'>
          <ModeButton
            $isActive={entry.side === CoinFlipSelection.Heads}
            onClick={() => setEntry({ side: CoinFlipSelection.Heads })}
            disabled={isGameAnimating}
          >
            Heads
          </ModeButton>
          <ModeButton
            $isActive={entry.side === CoinFlipSelection.Tails}
            onClick={() => setEntry({ side: CoinFlipSelection.Tails })}
            disabled={isGameAnimating}
          >
            Tails
          </ModeButton>
        </FormTab>
        <form onSubmit={e => e.preventDefault()}>
          <SDoubleInputRow className='mobile-form'>
            <AmountInputField
              control={control}
              maxNumber={bufferedMaxEntryAmount}
              entryAmount={entryAmountNum}
              balanceNumber={balanceNumber}
              balances={{ ...balances, currency: Number(balances.currency) }}
              bufferedMaxEntryAmount={bufferedMaxEntryAmount}
              isGameAnimating={isGameAnimating}
              stepZone={stepZone}
              networkStyle={networkStyle}
            />
            <NumberOfEntriesField
              isMobileScreen={isMobileScreen}
              control={control}
              maxCountForSide={DEFAULT_MAX_COUNT}
              numberOfEntries={numberOfEntries}
              entryAmount={entryAmount}
              isGameAnimating={isGameAnimating}
            />
          </SDoubleInputRow>
        </form>
      </FormLayout>
      <FormFooter>
        <GameButton formData={{ ...formData, side: entry.side }} entryAmountNum={entryAmountNum} />
      </FormFooter>
    </FormWrapper>
  )
}
