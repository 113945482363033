import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import numeral from 'numeral'
import CountUp from 'react-countup'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { FormAmountLabel, FormIndicator, FormLabel, FormLabelRow, SFormSection } from '../../style'
import { clamp, ensureNumber } from '@/utils/math'

interface NumberOfEntriesFieldProps {
  isMobileScreen: boolean
  control: Control<any>
  maxCountForSide: number
  numberOfEntries: number
  entryAmount: number
  isGameAnimating: boolean
}

export const NumberOfEntriesField: React.FC<NumberOfEntriesFieldProps> = ({
  isMobileScreen,
  control,
  maxCountForSide,
  numberOfEntries,
  entryAmount,
  isGameAnimating,
}) => {
  return (
    <SFormSection>
      <FormLabelRow>
        <FormLabel>
          <FormIndicator $isActive={numberOfEntries > 0} />
          <FormAmountLabel>{isMobileScreen ? 'Rounds' : 'Number of Entries'}</FormAmountLabel>
        </FormLabel>
        <FormLabel>
          {Number(entryAmount / (numberOfEntries || 1)) > 0 && (
            <FormAmountLabel>
              <CountUp
                end={Number(entryAmount / (numberOfEntries || 1))}
                decimals={2}
                duration={0.18}
                separator=','
                preserveValue
              />
              <span>&nbsp;/ {isMobileScreen ? 'round' : 'entry'}</span>
            </FormAmountLabel>
          )}
        </FormLabel>
      </FormLabelRow>
      <Controller
        name='numberOfEntries'
        control={control}
        rules={{
          required: `Must be ${maxCountForSide} or less.`,
          max: {
            message: `Must be less than ${maxCountForSide}`,
            value: maxCountForSide,
          },
          min: 1,
        }}
        render={({ field }) => {
          const MIN = 1
          const MAX = maxCountForSide
          const value = numeral(field.value).value()
          const clampedValue = clamp(ensureNumber(value), MIN, MAX)
          const { ref, ...props } = field

          return (
            <FareNumberInput
              {...props}
              getInputRef={ref}
              onChange={event => field.onChange(ensureNumber(event.currentTarget.value))}
              onBlur={event => {
                const val = clamp(ensureNumber(event.currentTarget.value), MIN, MAX)
                field.onChange(val)
              }}
              allowLeadingZeros={false}
              allowNegative={false}
              hasInputSlider={true}
              disabled={isGameAnimating}
              inputSliderProps={{
                value: clampedValue,
                onChange: sliderValue => field.onChange(sliderValue),
                min: MIN,
                max: MAX,
                step: 1,
                disabled: isGameAnimating,
              }}
            />
          )
        }}
      />
    </SFormSection>
  )
}
