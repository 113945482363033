/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormTab,
  FormWrapper,
  SFormSection,
} from '../style'
import { ModeButton } from '@/components/shared/Button/style'
import { PlinkoRiskSelection } from '@/lib/crypto/plinko'
import { Controller, useForm } from 'react-hook-form'
import { type ISUPlinkoContractForm } from '@/store/useSUContractStore'
import numeral from 'numeral'
import { FareNumberInputNew } from '@/components/shared/Input/FareNumberInputNew'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { FormLayout } from '../FormComponents/shared/FormLayout'
import { STripleRow } from '../FormComponents/shared/FormLayout/styles'
import { GameButton } from '@/components/shared/Button/GameButton'
import { getMaxCount } from '@/lib/crypto'
import { routeGameTypeMap } from '@/chains/lib'
import { type useUnityContext } from 'react-unity-webgl'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { useBalances } from '@/hooks/useBalances'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'
import { AmountInputField } from '../FormComponents/shared/AmountInputField'
import { NumberOfEntriesField } from '../FormComponents/shared/NumberOfEntriesField'

interface PlinkoProps {
  sendRiskLevelToUnity: (value: PlinkoRiskSelection) => void
  sendRowsToUnity: (value: number) => void
  unityContext: ReturnType<typeof useUnityContext>
}

export const PlinkoForm = ({
  sendRiskLevelToUnity,
  sendRowsToUnity,
  unityContext,
}: PlinkoProps) => {
  const { pathname } = useLocation()
  const isMobileScreen = useIsBreakpoint('sm')
  const networkStyle = useNetworkStyle()
  const balances = useBalances()
  const balanceNumber = Number(balances.currency)

  const { isLoaded } = unityContext

  const { control, register, watch, setValue, handleSubmit } = useForm<ISUPlinkoContractForm>({
    defaultValues: {
      side: [0, 8],
      entryAmount: 0,
      numberOfEntries: 1,
    },
  })
  const formData = watch()
  const { entryAmount, side, numberOfEntries } = formData
  const entryAmountNum = numeral(entryAmount).value() || 0
  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone({
    riskLevel: side[0],
    rowCount: side[1],
  })
  const { isGameAnimating } = useIsGameAnimating()

  const updateRiskAmount = (value: PlinkoRiskSelection) => {
    setValue('side.0', value)
    console.log('value', value)
  }

  useEffect(() => {
    if (isLoaded) {
      console.log('Unity risklevel', side[0])
      sendRiskLevelToUnity(side[0])
    } else {
      console.log('Unity risklevel sendMessageFailed')
    }
  }, [isLoaded, side[0]])

  useEffect(() => {
    if (isLoaded) {
      console.log('Unity rowCount', side[1])
      sendRowsToUnity(side[1])
    } else {
      console.log('Unity rowCount sendMessageFailed')
    }
  }, [isLoaded, side[1]])

  const [maxCountForSide, setMaxCountForSide] = useState(DEFAULT_MAX_COUNT)

  useEffect(() => {
    const maxCount = getMaxCount(routeGameTypeMap[pathname], {
      riskLevel: side[0],
      rowCount: side[1],
    } as any)
    // @NOTE: I could have done what I have done below with a single if but using if and else if makes it easier to understand
    if (numberOfEntries > maxCount) {
      // @NOTE: If their current count is above the new max count, I am setting their count to be the new max count
      setValue('numberOfEntries', maxCount)
    } else if (maxCountForSide === numberOfEntries && maxCount > maxCountForSide) {
      // @NOTE: If someone had fulled the slider to have max count, and if they move the slider for win chance that causes the max count to increase, I autyo increase their selected count to be new max
      setValue('numberOfEntries', maxCount)
    }
    setMaxCountForSide(maxCount)
  }, [side[0], side[1]])

  const plinkoFormTabsElems = useMemo(() => {
    return (
      <FormTab className='risk-tabs'>
        <ModeButton
          disabled={isGameAnimating}
          $isActive={side[0] === PlinkoRiskSelection.Low}
          onClick={() => updateRiskAmount(PlinkoRiskSelection.Low)}
        >
          {isMobileScreen ?
            <span>L</span>
          : <span>Low</span>}
        </ModeButton>
        <ModeButton
          disabled={isGameAnimating}
          $isActive={side[0] === PlinkoRiskSelection.Medium}
          onClick={() => updateRiskAmount(PlinkoRiskSelection.Medium)}
        >
          {isMobileScreen ?
            <span>M</span>
          : <span>Medium</span>}
        </ModeButton>
        <ModeButton
          disabled={isGameAnimating}
          $isActive={side[0] === PlinkoRiskSelection.High}
          onClick={() => updateRiskAmount(PlinkoRiskSelection.High)}
        >
          {isMobileScreen ?
            <span>H</span>
          : <span>High</span>}
        </ModeButton>
      </FormTab>
    )
  }, [isGameAnimating, isMobileScreen, side, updateRiskAmount])

  const plinkoRowsElems = useMemo(() => {
    return (
      <SFormSection>
        <FormLabelRow>
          <FormLabel>
            <FormIndicator $isActive={true} />
            <FormAmountLabel>Rows</FormAmountLabel>
          </FormLabel>
        </FormLabelRow>
        <Controller
          {...register('side.1', { required: true, max: 16, min: 8 })}
          control={control}
          defaultValue={8}
          render={({ field }) => {
            const rowsSliderValue = numeral(field.value).value() || 8

            return (
              <>
                <FareNumberInputNew
                  {...field}
                  onChange={event => {
                    field.onChange(numeral(event.target.value).value() as any)
                    console.log('event.target.value', event.target.value)
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  isAllowed={({ floatValue = 0 }) => {
                    return floatValue <= 16
                  }}
                  onFocus={event => event.target.select()}
                  hasInputSlider
                  disabled={isGameAnimating}
                  inputSliderProps={{
                    value: rowsSliderValue,
                    onChange: sliderValue => field.onChange(sliderValue),
                    min: 8,
                    max: 16,
                    step: 1,
                    disabled: isGameAnimating,
                  }}
                  // thousandSeparator=','
                  // inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              </>
            )
          }}
        />
        {/* {row.error} ERROR SHOULD BE HERE */}
      </SFormSection>
    )
  }, [control, isGameAnimating, register])

  const formAmountsElems = useMemo(() => {
    return (
      <>
        {!isMobileScreen && plinkoRowsElems}
        <AmountInputField
          control={control}
          maxNumber={1_000_000_000}
          entryAmount={entryAmountNum}
          balanceNumber={balanceNumber}
          balances={{ ...balances, currency: Number(balances.currency) }}
          bufferedMaxEntryAmount={bufferedMaxEntryAmount}
          isGameAnimating={isGameAnimating}
          stepZone={stepZone}
          networkStyle={networkStyle}
        />

        <NumberOfEntriesField
          isMobileScreen={isMobileScreen}
          control={control}
          maxCountForSide={maxCountForSide}
          numberOfEntries={numberOfEntries}
          entryAmount={entryAmount}
          isGameAnimating={isGameAnimating}
        />
      </>
    )
  }, [
    isMobileScreen,
    plinkoRowsElems,
    control,
    entryAmountNum,
    balanceNumber,
    balances,
    bufferedMaxEntryAmount,
    isGameAnimating,
    stepZone,
    networkStyle,
    maxCountForSide,
    numberOfEntries,
    entryAmount,
  ])

  return (
    <FormWrapper>
      <FormLayout>
        <STripleRow className='no-pd large-mobile-screens'>
          <SFormSection style={{ flexDirection: 'column' }}>
            <FormLabelRow>
              <FormLabel>
                <FormIndicator $isActive={entryAmountNum > 0} />
                <FormAmountLabel>Risk</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            {plinkoFormTabsElems}
          </SFormSection>
          {isMobileScreen && plinkoRowsElems}
        </STripleRow>

        <form>
          {isMobileScreen && <STripleRow style={{ padding: '0px' }}>{formAmountsElems}</STripleRow>}
          {!isMobileScreen && formAmountsElems}
        </form>
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{
            ...formData,
            side: {
              riskLevel: side[0],
              rowCount: side[1],
            },
          }}
          entryAmountNum={entryAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}
