import { modalVariants, SGradientWalletModalContent } from '@/components/Privy/SelectWalletModal'
import {
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalOverlayContainer,
  ModalTitle,
  ModalWrapper,
} from './styles'
import { Button, ButtonEnum } from '../Button'
import { SVGS } from '@/assets'
import { motion, type Variants } from 'framer-motion'

interface ModalCardProps {
  title: string
  description?: React.ReactNode
  children: React.ReactNode
  submit?: React.ReactNode
  isVisible?: boolean
  setIsVisible?: (isVisible: boolean) => void
  className?: string
  stepIdx?: number
  onClose?: () => void
  setStepIdx?: (idx: number) => void
  maxHeight?: string
}

const prevButtonVariant: Variants = {
  initial: { x: 0 },
  animate: { x: 0 },
  hover: { x: [0, -5, 0] },
}

export const modalOverlayVarient: Variants = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.12,
    },
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.12,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
}

const ModalCard = forwardRef<HTMLDivElement, ModalCardProps>(
  (
    {
      title,
      description,
      children,
      submit,
      onClose,
      isVisible,
      setIsVisible = () => {},
      className,
      stepIdx = 0,
      setStepIdx = () => {},
      maxHeight,
    },
    ref
  ) => {
    const handleClose = () => {
      setIsVisible(false)
      onClose?.()
    }

    useEffect(() => {
      if (!isVisible) {
        onClose?.()
      }
    }, [isVisible, onClose])

    const goBack = () => setStepIdx(0)

    if (!isVisible) {
      return null
    }

    const renderFooter = () => (
      <ModalFooter>
        {stepIdx > 0 && stepIdx !== 2 && (
          <Button
            onClick={goBack}
            buttonType={ButtonEnum.BASE}
            disabled={false}
            style={{ border: 'none', backgroundColor: 'transparent' }}
          >
            <motion.img
              src={SVGS.caretLeft}
              width={18}
              initial='initial'
              animate='animate'
              whileHover='hover'
              variants={prevButtonVariant}
            />
          </Button>
        )}
        <div className='submit-button'>{submit}</div>
      </ModalFooter>
    )

    const renderHeader = () => (
      <ModalHeader
        style={className === 'fund-modal-content' ? { width: '100%', padding: '0px' } : {}}
      >
        <ModalTitle
          style={
            className === 'fund-modal-content' ?
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#1c1c1e',
                marginBlock: 0,
                fontFamily: 'system-ui, sans-serif',
              }
            : {}
          }
        >
          {title}
          {className === 'fund-modal-content' && (
            <Button
              buttonType={ButtonEnum.BASE}
              disabled={false}
              onClick={handleClose}
              style={{
                height: '30px',
                width: '30px',
                padding: '0',
                border: 'none',
                backgroundColor: 'transparent',
                fontFamily: 'system-ui, sans-serif',
              }}
            >
              <img src={SVGS.closeIcon} alt='close' width={28} />
            </Button>
          )}
        </ModalTitle>
        {description && (
          <ModalDescription
            style={
              className === 'fund-modal-content' ?
                {
                  fontFamily: 'system-ui, sans-serif',
                  lineHeight: '1.25',
                }
              : {}
            }
          >
            {description}
          </ModalDescription>
        )}
      </ModalHeader>
    )

    const renderContent = () => (
      <ModalContent className={className} $maxHeight={maxHeight}>
        {renderHeader()}
        <ModalBody>{children}</ModalBody>
        {renderFooter()}
      </ModalContent>
    )

    return (
      <ModalOverlayContainer
        variants={modalOverlayVarient}
        initial='initial'
        animate='animate'
        exit='exit'
      >
        <ModalWrapper
          ref={ref}
          variants={modalVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          {className === 'fund-modal-content' ?
            renderContent()
          : <SGradientWalletModalContent>{renderContent()}</SGradientWalletModalContent>}
        </ModalWrapper>
      </ModalOverlayContainer>
    )
  }
)

export default ModalCard
