import { proxy } from 'valtio'

export const supportModalState = proxy({
  isVisible: false,
})

export const useSupportModal = () => {
  const showSupportModal = () => {
    supportModalState.isVisible = true
  }

  const hideSupportModal = () => {
    supportModalState.isVisible = false
  }

  return {
    showSupportModal,
    hideSupportModal,
  }
}
