import { useForm } from 'react-hook-form'
import { FormFooter, FormTab, FormWrapper } from '../style'
import numeral from 'numeral'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { RPSSelection } from '@/lib/crypto/rps'
import { useRPSGameState } from '@/store/useGameStateStore'
import { GameButton } from '../../shared/Button/GameButton'
import { ModeButton } from '../../shared/Button/style'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { FormLayout } from '../FormComponents/shared/FormLayout'
import { SDoubleInputRow } from '../FormComponents/shared/FormLayout/styles'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useBalances } from '@/hooks/useBalances'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'
import { AmountInputField } from '../FormComponents/shared/AmountInputField'
import { NumberOfEntriesField } from '../FormComponents/shared/NumberOfEntriesField'

export const RPSForm = () => {
  const networkStyle = useNetworkStyle()
  const balances = useBalances()
  const isMobileScreen = useIsBreakpoint('sm')

  const balanceNumber = Number(balances.currency)

  const { selectedSide, setSelectedSide } = useRPSGameState(state => ({
    selectedSide: state.entry.side,
    setSelectedSide: (side: number) => state.setEntry({ side }),
  }))

  const { control, watch } = useForm<ISUContractForm>({
    defaultValues: {
      side: 0,
      entryAmount: 0,
      numberOfEntries: 1,
      stopGain: 0,
      stopLoss: 0,
    },
  })
  const { isGameAnimating } = useIsGameAnimating()
  const formData = watch()
  const { entryAmount, numberOfEntries } = formData
  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone(formData.side)

  const entryAmountNum = numeral(entryAmount).value() || 0

  return (
    <FormWrapper>
      <FormLayout>
        <FormTab className='mobile-tabs'>
          <ModeButton
            $isActive={selectedSide === RPSSelection.Rock}
            onClick={() => setSelectedSide(RPSSelection.Rock)}
            disabled={isGameAnimating}
          >
            <span>Rock</span>
          </ModeButton>
          <ModeButton
            $isActive={selectedSide === RPSSelection.Paper}
            onClick={() => setSelectedSide(RPSSelection.Paper)}
            disabled={isGameAnimating}
          >
            <span>Paper</span>
          </ModeButton>
          <ModeButton
            $isActive={selectedSide === RPSSelection.Scissors}
            onClick={() => setSelectedSide(RPSSelection.Scissors)}
            disabled={isGameAnimating}
          >
            <span>Scissors</span>
          </ModeButton>
        </FormTab>
        <form onSubmit={e => e.preventDefault()}>
          <SDoubleInputRow className='mobile-form'>
            <AmountInputField
              control={control}
              maxNumber={1_000_000_000}
              entryAmount={entryAmountNum}
              balanceNumber={balanceNumber}
              balances={{ ...balances, currency: Number(balances.currency) }}
              bufferedMaxEntryAmount={bufferedMaxEntryAmount}
              isGameAnimating={isGameAnimating}
              stepZone={stepZone}
              networkStyle={networkStyle}
            />

            <NumberOfEntriesField
              isMobileScreen={isMobileScreen}
              control={control}
              maxCountForSide={DEFAULT_MAX_COUNT}
              numberOfEntries={numberOfEntries}
              entryAmount={entryAmount}
              isGameAnimating={isGameAnimating}
            />
          </SDoubleInputRow>
        </form>
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{ ...formData, side: selectedSide }}
          entryAmountNum={entryAmountNum}
        />
      </FormFooter>
    </FormWrapper>
  )
}
