import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FareNumberInputNew } from '@/components/shared/Input/FareNumberInputNew'
import {
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormWrapper,
  SFormSection,
} from '@/components/Forms/style'
import numeral from 'numeral'
import { useCrashGameStore } from '@/store/useCrashGameStore'
import { GameButton } from '@/components/shared/Button/GameButton'
import { FormLayout } from '@/components/Forms/FormComponents/shared/FormLayout'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { useBalances } from '@/hooks/useBalances'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'
import { useShallow } from 'zustand/react/shallow'
import { AmountInputField } from '@/components/Forms/FormComponents/shared/AmountInputField'

interface CrashFormData {
  side: number
  entryAmount: number
  numberOfEntries: number
  stopLoss: number
  stopGain: number
  autoCashoutMultiplier: number
}

const CrashFormComponent: React.FC = () => {
  const { control, watch } = useForm<CrashFormData>({
    defaultValues: {
      side: 200,
      entryAmount: 0,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
      autoCashoutMultiplier: 2,
    },
  })
  useLocation()
  const networkStyle = useNetworkStyle()
  const formData = watch()
  const balances = useBalances()
  const balanceNumber = Number(balances.currency)
  // Watch only necessary fields to minimize re-renders
  const entryAmount = watch('entryAmount')
  const autoCashoutMultiplier = watch('autoCashoutMultiplier')
  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone(
    Math.round(formData.autoCashoutMultiplier * 100)
  )
  const { setAutoCashoutMultiplier } = useCrashGameStore(
    useShallow(state => ({ setAutoCashoutMultiplier: state.setAutoCashoutMultiplier }))
  )
  const { isGameAnimating } = useIsGameAnimating()

  return (
    <FormWrapper>
      <FormLayout>
        <AmountInputField
          control={control}
          maxNumber={bufferedMaxEntryAmount}
          entryAmount={entryAmount}
          balanceNumber={balanceNumber}
          balances={{ ...balances, currency: Number(balances.currency) }}
          bufferedMaxEntryAmount={bufferedMaxEntryAmount}
          isGameAnimating={isGameAnimating}
          stepZone={stepZone}
          networkStyle={networkStyle}
        />

        <SFormSection>
          <FormLabelRow>
            <FormIndicator
              $isActive={autoCashoutMultiplier > 1.01 && autoCashoutMultiplier <= 500}
            />
            <FormLabel>Cashout Multiplier</FormLabel>
          </FormLabelRow>
          <Controller
            name='autoCashoutMultiplier'
            control={control}
            rules={{ required: true, min: 1.01, max: 500 }}
            render={({ field }) => {
              return (
                <FareNumberInputNew
                  {...field}
                  onChange={event => {
                    const value = numeral(event.target.value).value() ?? 0
                    field.onChange(value)
                    setAutoCashoutMultiplier(value)
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  decimalScale={2}
                  hasInputSlider
                  disabled={isGameAnimating}
                  inputSliderProps={{
                    value: field.value,
                    onChange: sliderValue => {
                      field.onChange(sliderValue)
                      setAutoCashoutMultiplier(sliderValue)
                    },
                    min: 1.01,
                    max: 500,
                    step: 0.01,
                    disabled: isGameAnimating,
                  }}
                />
              )
            }}
          />
        </SFormSection>
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{
            ...formData,
            side: Math.round(formData.autoCashoutMultiplier * 100),
          }}
          entryAmountNum={entryAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}

export const CrashForm = memo(CrashFormComponent)
