import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import numeral from 'numeral'
import CountUp from 'react-countup'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { FormAmountLabel, FormIndicator, FormLabel, FormLabelRow, SFormSection } from '../../style'

interface AmountInputFieldProps {
  control: Control<any>
  maxNumber: number
  entryAmount: number
  balanceNumber: number
  balances: { currency: number }
  bufferedMaxEntryAmount: number
  isGameAnimating: boolean
  stepZone: number
  networkStyle: { currencyIcon: string; currencyName: string }
}

export const AmountInputField: React.FC<AmountInputFieldProps> = ({
  control,
  maxNumber,
  entryAmount,
  balanceNumber,
  balances,
  bufferedMaxEntryAmount,
  isGameAnimating,
  stepZone,
  networkStyle,
}) => {
  return (
    <SFormSection>
      <FormLabelRow>
        <FormLabel>
          <FormIndicator $isActive={entryAmount > 0} />
          <FormAmountLabel>Amount</FormAmountLabel>
        </FormLabel>
        <FormLabel>
          {balanceNumber > 0 && (
            <FormAmountLabel>
              <CountUp end={balanceNumber} decimals={2} duration={2} separator=',' preserveValue />
            </FormAmountLabel>
          )}
        </FormLabel>
      </FormLabelRow>
      <Controller
        name='entryAmount'
        control={control}
        rules={{ required: true, max: maxNumber, min: 0 }}
        defaultValue={0}
        render={({ field }) => {
          const { ref, ...props } = field
          const value = numeral(field.value).value() || 0
          const isDisabled = (numeral(balances.currency).value() || 0) === 0 || isGameAnimating

          return (
            <FareNumberInput
              {...props}
              getInputRef={ref}
              onChange={event => field.onChange(numeral(event.target.value).value() as any)}
              allowLeadingZeros={false}
              allowNegative={false}
              thousandSeparator=','
              decimalScale={2}
              hasInputSlider
              disabled={isDisabled}
              inputSuffix={
                <img src={networkStyle.currencyIcon} alt={networkStyle.currencyName} width={20} />
              }
              inputSliderProps={{
                value,
                onChange: sliderValue => field.onChange(sliderValue),
                min: 0,
                max: bufferedMaxEntryAmount,
                step: stepZone,
                disabled: isDisabled,
              }}
            />
          )
        }}
      />
    </SFormSection>
  )
}
